import { PageProps } from "gatsby";
import React from "react";

import { Staff } from "../../components/pages/Staff";

import { dnProps } from "../../js/utils";

const StaffPage = (props: PageProps) => <Staff {...{ ...dnProps, ...props }} />;

export default StaffPage;
